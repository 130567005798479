import { useSelector } from 'react-redux';
/**Hooks */
import { useLogin } from '../../hooks';
/**Utilities */
import { ErrorValidate } from '../../utilities';
/**Components */
import { LoadingState } from '../../components/shared';
/**Redux */
import { AppStore } from '../../redux/store';

export const Login = () => {
  const loadingState = useSelector((store: AppStore) => store.loading);
  const { handleInputChange, formik } = useLogin();

  return (
    <div className="layout__auth">
      <div className="p-0 container-fluid">
        <div className="g-0 row">
          <div className="col-lg-4">
            <div className="p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <div className="justify-content-center row">
                  <div className="col-lg-9">
                    <div>
                      <div className="text-center">
                        <div>
                          <img className="img-logo" src="/images/logo.jpg" alt="" />
                        </div>
                        <h4 className="fs-5 mt-4">Bienvenido !</h4>
                        <p className="text-muted">Inicie sesión para continuar.</p>
                      </div>
                      <div className="p-2 mt-5">
                        <form className="form form-horizontal" onSubmit={formik.handleSubmit}>
                          <div className="auth-form-group-custom mb-4">
                            <i className="far fa-user auti-custom-input-icon"></i>
                            <label className="form-label">Correo o DNI</label>
                            <div className="mb-3">
                              <input
                                name="user"
                                value={formik.values.user}
                                onChange={handleInputChange}
                                placeholder="Ingrese correo o DNI"
                                type="text"
                                className={'form-control' + (formik.errors.user && formik.touched.user ? ' is-invalid' : '')}
                              />
                              <ErrorValidate state={formik.errors.user} />
                            </div>
                          </div>
                          <div className="auth-form-group-custom mb-4 form-group">
                            <i className="auti-custom-input-icon ri-lock-line"></i>
                            <label className="">Contraseña</label>
                            <div className="form-group">
                              <input
                                name="password"
                                value={formik.values.password}
                                onChange={handleInputChange}
                                placeholder="Ingresar contraseña"
                                type="password"
                                className={'form-control' + (formik.errors.password && formik.touched.password ? ' is-invalid' : '')}
                              />
                              <ErrorValidate state={formik.errors.password} />
                            </div>
                          </div>
                          <div className="mt-5 text-center">
                            <button type="submit" className="w-md btn btn-primary">
                              Ingresar
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="mt-5 text-center">
                        <p>
                          <b>
                            © 2024 - Todos los derechos reservados <i className="ri-heart-fill text-danger"></i>
                          </b>
                        </p>
                        <p>Versión 1.5.0</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="authentication-bg">
              <div className="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      {loadingState.isLoading ? <LoadingState /> : null}
    </div>
  );
};

export default Login;
