/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
/**Hooks */
import { useDespatches, useSales } from '../../../hooks';
/**Redux */
import { setearIsStateToggle } from '../../../redux/states/loading.slice';
/**Models */
import { DespatchRequest, DespatchItemRequest, DocumentoRelacionadoRequest } from '../../../models/requests';
/**Moment */
import moment from 'moment';
/**Validations */
import { useFormik } from 'formik';
import * as Yup from 'yup';
/**Utilities */
import { SweetAlert } from '../../../utilities';
/*Components */
import DatosTraslado from './DatosTraslado';
import NavTabs from './NavTabs';
import DatosTransportista from './DatosTransportista';
import DatosConductor from './DatosConductor';
import DatosDestino from './DatosDestino';
import ProductosBody from './ProductosBody';
import ProductosHeader from './ProductosHeader';
import ModalComponent from '../../../components/modal';
import ImportarItems from './ImportarItems';
import Header from './Header';
import Finish from './Finish';

interface DataModalState {
  size: string;
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
  content?: any;
}

export const DespatchesStore = () => {
  //DATA DEFAULT
  const defaultFormData: DespatchRequest = {
    fechaEmision: moment().format('YYYY-MM-DD'),
    tipoTransporte: '',
    motivoTraslado: '01',
    observaciones: '',
    cliente_id: '',
    datosTraslado: {
      fecha: moment().format('YYYY-MM-DD'),
      pesoUnidadMedida: '',
      pesoBrutoTotal: '',
      numeroBultos: '',
      indicadorDeEnvio: '',
    },
    datosTransportista: {
      tipoDocumento: '6',
      numeroDocumento: '',
      denominacion: '',
      numeroMTC: '',
      numeroPlaca: '',
      validatePlaca: null,
    },
    datosChofer: {
      tipoDocumento: '',
      numeroDocumento: '',
      numeroLicencia: '',
      validateLicencia: null,
      nombres: '',
      apellidos: '',
    },
    puntoPartida: {
      selectedUbigeo: {
        label: '[140114] LAMBAYEQUE - CHICLAYO - SANTA ROSA',
        value: '140114',
      },
      ubigeo: '140114',
      direccion: 'CAL. 07 DE JUNIO NRO. 840 A.H. LOS BALSARES',
    },
    puntoLlegada: {
      selectedUbigeo: null,
      ubigeo: '',
      direccion: '',
    },
    items: [],
    documentosRelacionados: [],
  };

  const [activeTab, setActiveTab] = useState('tab1');
  /**Redux */
  const dispatch = useDispatch();
  /**Data */
  const [requirements, setRequirements] = useState<any | null>(null);
  const [loadedComponent, setLoadedComponent] = useState(false);
  //Hooks
  const { getRequirements, getUbigeos, getDocuments, getPlacas, getLicencias, storeDespatch, personSearch } = useDespatches();
  const { clientSearch, productSearch } = useSales();
  //FormData
  const [formData, setFormData] = useState<DespatchRequest>(defaultFormData);
  const validationSchema = Yup.object().shape({
    fechaEmision: Yup.string().required('Este campo es obligatorio.'),
    tipoTransporte: Yup.string().required('Este campo es obligatorio.'),
    cliente_id: Yup.string().required('Este campo es obligatorio.'),
  });
  const [backendErrors, setBackendErros] = useState(null);
  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [dataModalResourceState, setDataModalResourceState] = useState<DataModalState>({
    size: '',
    type: '',
    buttonSubmit: null,
    row: null,
    title: null,
    requirements: [],
    onCloseModalForm: () => {},
    errosBackend: {},
    content: null,
  });

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  //Data inicial [GET]
  useEffect(() => {
    const dataInicial = () => {
      getRequirements().then((response) => {
        setRequirements(response);
        setLoadedComponent(true);
      });
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [getRequirements, loadedComponent]);

  useEffect(() => {
    dispatch(setearIsStateToggle('true'));
    return () => {
      dispatch(setearIsStateToggle('false'));
    };
  }, [dispatch]);

  //Formik
  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: async () => {
      console.log(formData);
      storeDespatch(formData)
        .then((response) => {
          console.log(response);
          if (response.success) {
            setDataModalResourceState({
              size: 'modal-md',
              type: 'finish-sale',
              buttonSubmit: null,
              row: formData,
              title: 'Guía de Remisión Remitente finalizada',
              requirements: [],
              onCloseModalForm: onCloseModalForm,
              errosBackend: {},
              content: <Finish viewFooter={false} onCloseModalForm={onCloseModalForm} response={response} />,
            });
            setActiveTab('tab1');
            setStateModal(true);
            setIsModalOpen(true);
            setFormData(defaultFormData);
            formik.setValues(defaultFormData);
            formik.setFieldValue('selectedClient', null);
            setBackendErros(null);
          } else {
            console.log(response);
            SweetAlert.error('Error', response.error != null ? response.error : 'Ocurrio un error al comunicarse con SUNAT.');
          }
        })
        .catch((error) => {
          setBackendErros(error.response.data.errors);
        });
    },
  });
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue(e.target.name, e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChangeValue = (key: any, fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value);
    setFormData({
      ...formData,
      [key]: {
        ...formData[key],
        [fieldName]: value,
      },
    });
  };

  const handleSelectClient = (selectedOption: any) => {
    formik.setFieldValue('cliente_id', selectedOption.value);
    setFormData({
      ...formData,
      cliente_id: selectedOption.value,
      puntoLlegada: {
        ...formData.puntoLlegada,
        selectedUbigeo: {
          value: selectedOption.ubigeo,
          label: selectedOption.ubigeo_name,
        },
        ubigeo: selectedOption.ubigeo,
        direccion: selectedOption.address_despatch,
      },
    });
  };

  const handleInputChangeValue = (key: any, fieldName: string, value: string) => {
    formik.setFieldValue(fieldName, value);
    setFormData({
      ...formData,
      [key]: {
        ...formData[key],
        [fieldName]: value,
      },
    });
  };

  const handleProductSelected = (index: number, fieldName: string, value: string) => {
    const updatedItems = [...formData.items];
    updatedItems[index][fieldName] = value;
    setFormData({
      ...formData,
      items: updatedItems,
    });
  };

  const handleDocumentSelected = (index: number, fieldName: string, value: string) => {
    const updatedItems = [...formData.documentosRelacionados];
    updatedItems[index][fieldName] = value;
    setFormData({
      ...formData,
      documentosRelacionados: updatedItems,
    });
  };

  const handleImportItems = (items: DespatchItemRequest[]) => {
    setFormData({
      ...formData,
      items: items,
    });
    setStateModal(false);
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSearchLicencia = (nombres: string, apellidos: string, numeroLicencia: string, validateLicencia: Boolean) => {
    setFormData({
      ...formData,
      datosChofer: {
        ...formData.datosChofer,
        nombres: nombres,
        apellidos: apellidos,
        numeroLicencia: numeroLicencia,
        validateLicencia: validateLicencia,
      },
    });
  };

  const handleSearchUbigeo = (key: string, ubigeo: string, selectedUbigeo: any) => {
    setFormData({
      ...formData,
      [key]: {
        ...formData[key],
        ubigeo: ubigeo,
        selectedUbigeo: selectedUbigeo,
      },
    });
  };

  const onClickAddItem = () => {
    const newItem: DespatchItemRequest = {
      product_id: '',
      codigo: '',
      descripcion: '',
      adicional: '',
      unidadMedida: '',
      cantidad: '',
      view: true,
    };
    setFormData({
      ...formData,
      items: [...formData.items, newItem],
    });
  };

  const onClickAddDocumento = () => {
    const newDocumento: DocumentoRelacionadoRequest = {
      tipoDocumento: '',
      numeroDocumento: '',
    };
    setFormData({
      ...formData,
      documentosRelacionados: [...formData.documentosRelacionados, newDocumento],
    });
  };

  const onClickDeleteItem = (index: number) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      items: updatedItems,
    });
  };

  const onClickDeleteDocument = (index: number) => {
    const updatedDocuments = formData.documentosRelacionados.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      documentosRelacionados: updatedDocuments,
    });
  };

  /**MODAL IMPORTS ITEMS */
  const onCloseModalForm = () => {
    setStateModal(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onClickImportItems = () => {
    setDataModalResourceState({
      size: 'modal-md',
      type: 'import',
      buttonSubmit: 'Importar',
      row: null,
      title: 'Importar productos',
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
      content: (
        <ImportarItems
          clientId={formData.cliente_id}
          onCloseModalForm={onCloseModalForm}
          searchDocuments={getDocuments}
          handleImportItems={handleImportItems}
        />
      ),
    });
    setStateModal(true);
    setIsModalOpen(true);
  };

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <form className="form" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <h4 className="text-center">Generar nueva Guía de Remisión Remitente</h4>
                    </div>
                    <Header
                      requirements={requirements}
                      handleSelectChange={handleSelectChange}
                      formik={formik}
                      clientSearch={clientSearch}
                      handleSelectClient={handleSelectClient}
                    />

                    <div className="col-md-12 mt-2">
                      <NavTabs
                        itemsCount={formData.items.length}
                        errors={backendErrors}
                        activeTab={activeTab}
                        handleTabChange={handleTabChange}
                        tipoTransporte={formik.values.tipoTransporte}
                      />
                      <div className="tab-content">
                        <div className={`tab-pane p-3 fade ${activeTab === 'tab1' ? 'show active' : ''}`}>
                          <DatosTraslado
                            handleTextAreaChange={handleTextAreaChange}
                            observaciones={formData.observaciones}
                            clientId={formData.cliente_id}
                            onClickAddDocumento={onClickAddDocumento}
                            onClickDeleteDocument={onClickDeleteDocument}
                            searchDocuments={getDocuments}
                            handleDocumentSelected={handleDocumentSelected}
                            errors={backendErrors}
                            documentosRelacionados={formData.documentosRelacionados}
                            requirements={requirements}
                            datosTraslado={formData.datosTraslado}
                            handleInputChangeValue={handleInputChangeValue}
                            handleSelectChangeValue={handleSelectChangeValue}
                          />
                        </div>
                        <div className={`tab-pane p-3 fade ${activeTab === 'tab2' ? 'show active' : ''}`}>
                          <DatosTransportista
                            errors={backendErrors}
                            getPlacas={getPlacas}
                            personSearch={personSearch}
                            tipoTransporte={formData.tipoTransporte}
                            datosTransportista={formData.datosTransportista}
                            handleInputChangeValue={handleInputChangeValue}
                          />
                        </div>
                        <div className={`tab-pane p-3 fade ${activeTab === 'tab3' ? 'show active' : ''}`}>
                          <DatosConductor
                            errors={backendErrors}
                            datosChofer={formData.datosChofer}
                            getLicencias={getLicencias}
                            handleSearchLicencia={handleSearchLicencia}
                            handleInputChangeValue={handleInputChangeValue}
                            handleSelectChangeValue={handleSelectChangeValue}
                          />
                        </div>
                        <div className={`tab-pane p-3 fade ${activeTab === 'tab4' ? 'show active' : ''}`}>
                          <DatosDestino
                            errors={backendErrors}
                            handleSearchUbigeo={handleSearchUbigeo}
                            handleInputChangeValue={handleInputChangeValue}
                            handleSelectChangeValue={handleSelectChangeValue}
                            ubigeos={getUbigeos}
                            puntoPartida={formData.puntoPartida}
                            puntoLlegada={formData.puntoLlegada}
                          />
                        </div>
                        <div className={`tab-pane p-3 fade ${activeTab === 'tab5' ? 'show active' : ''}`}>
                          <div className="row border-sales mt-4">
                            <ProductosHeader onClickAddItem={onClickAddItem} onClickImportItems={onClickImportItems} />

                            <ProductosBody
                              errors={backendErrors}
                              onClickDeleteItem={onClickDeleteItem}
                              items={formData.items}
                              productSearch={productSearch}
                              handleProductSelected={handleProductSelected}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2" style={{ fontSize: '12px' }}>
                      <span className="text-danger">*</span>
                      <span>Campos obligatorios</span>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                      <div className="d-grid col-md-4">
                        <button type="submit" className="btn btn-primary btn-lg btn-block">
                          Generar comprobante
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Resource */}
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={'static'}
          title={dataModalResourceState.title}
          content={dataModalResourceState.content}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default DespatchesStore;
